import React from 'react'
import classNames from 'classnames'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import styles from './AuthForm.module.scss'

interface Props {
  className?: string
  title: string
}

const AuthForm: React.FC<Props> = ({ className, title, children }) => {
  return (
    <div className={classNames(className)}>
      <FlexColumn className={styles.wrapper} gap="30px" align="normal">
        <h1 className={styles.title}>{title}</h1>
        {children}
      </FlexColumn>
    </div>
  )
}

export default AuthForm
