import React from 'react'
import styles from './BenefitsTitle.module.scss'

const BenefitsTitle = () => {
  return (
    <h1 className={styles.title}>
      Bishopi provides wide range of effective domaintools & services
    </h1>
  )
}

export default BenefitsTitle
