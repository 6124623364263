import { notificationController } from 'notificationController/notificationController'
import { useMutation } from 'react-query'
import { authService } from 'services/auth.service'
import { useGetUser } from './useGetUser'
import Router from 'next/router'
import { RouteEnum } from 'enums/RouteEnum'
import { setCookie } from 'utils/setCookie'

const fetchLogin = async (details) => {
  const { data } = await authService.logIn(details)

  return data
}

export const useLogIn = (query: string | string[]) => {
  const { refetch: getUser } = useGetUser()

  const mutation = useMutation(fetchLogin, {
    onSuccess: (data) => {
      setCookie('access', data.token, {
        'max-age': 60 * 60 * 24 * 7, // 7 days
        sameSite: 'strict',
        path: '/',
        secure: process.env.NODE_ENV !== 'development',
      })
      getUser()
      notificationController.show({
        description: 'You have succesfully logged in',
        status: 'success',
      })
      Router.push(
        query ? query.toString() : RouteEnum.DASHBOARD_CUSTOMER_PORTAL
      )
    },
  })

  return mutation
}
