import React from 'react'
import AuthSection from '../AuthSection/AuthSection'
import LogInForm from './components/LogInForm/LogInForm'
import Card from 'components/common/Card/Card'
import { LogInDetails } from 'models/auth.interface'
import { Alert } from 'components/common/Alert'
import { FlexColumn } from 'components/common/Containers'

export const initialDetailsState: LogInDetails = {
  email: '',
  password: '',
}

interface Props {
  verified: boolean
}

const LogIn: React.FC<Props> = ({ verified }) => {
  return (
    <AuthSection
      form={
        <div data-aos="fade-right">
          <FlexColumn gap="1rem" align="normal">
            {verified && (
              <Alert
                status="info"
                title="Success"
                message="Email verified successfully"
              />
            )}

            <Card>
              <LogInForm />
            </Card>
          </FlexColumn>
        </div>
      }
    />
  )
}

export default LogIn
