import React from 'react'
import BenefitItem from './components/BenefitItem/BenefitItem'
import data from './data/data.json'
import styles from './BenefitsList.module.scss'

const BenefitsList: React.FC = () => {
  return (
    <ul className={styles.list}>
      {data.benefits.map((benefit) => (
        <BenefitItem key={benefit} text={benefit} />
      ))}
    </ul>
  )
}

export default BenefitsList
