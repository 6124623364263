import React from 'react'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import styles from './BenefitsSlogan.module.scss'

const BenefitsSlogan = () => {
  return (
    <FlexColumn gap="2.25rem" className={styles.wrapper}>
      <span className={styles.border} />

      <h2 className={styles.slogan}>
        Empower your domain investing business with the most advanced and
        powerful tools and services.
      </h2>

      <span className={styles.border} />
    </FlexColumn>
  )
}

export default BenefitsSlogan
