import React, { useEffect, useState } from 'react'
import LogIn from 'components/Auth/LogIn/LogIn'
import { AuthLayout } from 'layouts'
import { Page } from 'models/page.interface'
import { getCustomServerSideProps } from 'utils/getCustomServerSideProps'
import CustomNextSeo from 'components/CustomNextSeo'
import { useRouter } from 'next/router'
import Script from 'next/script'

const Login: Page = () => {
  const router = useRouter()

  const [verified, setVerified] = useState(false)

  // Param added by redirect from /accounts/auth/verification/[token] route's redirect
  useEffect(() => {
    if (
      router.query?.verified === 'true' &&
      !router.asPath.includes('verified') // to avoid setting verified manually
    ) {
      setVerified(true)
    }
  }, [router])

  return (
    <>
      <CustomNextSeo
        title="Login to your account | Bishopi.io"
        description="Access your Bishopi.io account with ease. Log in here to utilize our comprehensive domain tools and services. Secure and user-friendly for all your domain needs."
        canonical="https://bishopi.io/login"
      />
      <LogIn verified={verified} />
    </>
  )
}

Login.getLayout = (page: React.ReactElement) => <AuthLayout>{page}</AuthLayout>

export const getServerSideProps = getCustomServerSideProps

export default Login
