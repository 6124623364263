import React, { useEffect, useState } from 'react'
import BenefitsList from './components/BenefitsList/BenefitsList'
import BenefitsTitle from './components/BenefitsTitle/BenefitsTitle'
import BenefitsSlogan from './components/BenefitsSlogan/BenefitsSlogan'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import styles from './AuthBenefits.module.scss'

const AuthBenefits: React.FC = () => {
  const [showChild, setShowChild] = useState(false)

  // hack for late render
  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    return null
  }

  return (
    <FlexColumn
      className={styles.authBenefitsWrapper}
      gap="3.5rem"
      align="normal"
    >
      <BenefitsTitle />
      <BenefitsList />
      <BenefitsSlogan />
    </FlexColumn>
  )
}

export default AuthBenefits
