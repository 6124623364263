import React from 'react'
import AuthBenefits from 'components/Auth/AuthBenefits/AuthBenefits'
import { Container } from 'components/common/Containers/Container/Container'
import styles from './AuthSection.module.scss'

interface Props {
  form: React.ReactNode
}

const AuthSection: React.FC<Props> = ({ form }) => {
  return (
    <Container>
      <div className={styles.wrapper}>
        <AuthBenefits />
        {form}
      </div>
    </Container>
  )
}

export default AuthSection
