import React from 'react'
import { AiFillStar } from 'react-icons/ai'
import styles from './BenefitItem.module.scss'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'

interface Props {
  text: string
}

const BenefitItem: React.FC<Props> = ({ text }) => {
  return (
    <li className={styles.wrapper}>
      <FlexRow gap="0.875rem" align="center" justify="flex-start">
        <AiFillStar />

        <span className={styles.text}>{text}</span>
      </FlexRow>
    </li>
  )
}

export default BenefitItem
