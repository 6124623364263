import React from 'react'
import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertProps as ChakraAlertProps,
  AlertTitle,
} from '@chakra-ui/react'
import { FlexColumn, FlexRow } from '../Containers'

interface AlertProps {
  status: ChakraAlertProps['status']
  title?: string
  message: React.ReactNode
}

const Alert: React.FC<AlertProps> = ({ status, title, message }) => {
  return (
    <ChakraAlert status={status}>
      <FlexColumn gap="10px">
        <FlexRow gap="0px">
          <AlertIcon />
          <AlertTitle>{title}</AlertTitle>
        </FlexRow>

        <AlertDescription>{message}</AlertDescription>
      </FlexColumn>
    </ChakraAlert>
  )
}

export default Alert
