import React from 'react'
import * as yup from 'yup'
import AuthForm from 'components/Auth/AuthForm/AuthForm'
import { Button } from 'components/common/Buttons/Button'
import A from 'components/common/A/A'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import Input from 'components/common/Input/Input'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { RouteEnum } from 'enums/RouteEnum'
import {
  emailEmptyError,
  emailError,
  passwordEmptyError,
  recaptchaError,
} from 'constants/errors'
import { Form, Formik } from 'formik'
import { initialDetailsState } from '../../LogIn'
import Recaptcha from 'components/common/Recaptcha/Recaptcha'
import { FlexRow } from 'components/common/Containers'
import { useRouter } from 'next/router'
import { useLogIn } from '../../../../../hooks/useLogin'

interface Props {}

const LogInSchema = yup.object().shape({
  email: yup.string().required(emailEmptyError).email(emailError),
  password: yup.string().required(passwordEmptyError),
  g_recaptcha_response: yup.string().required(recaptchaError),
})

const LogInForm: React.FC<Props> = () => {
  const recaptchaRef = React.useRef(null)
  const router = useRouter()

  const { isLoading, mutateAsync } = useLogIn(router.query?.from)

  const submitHandler = async (values, { setErrors }) => {
    try {
      await mutateAsync(values).catch((error) => {
        setErrors({
          email: error.message.length ? error.message[0] : undefined,
          g_recaptcha_response: error?.message?.g_recaptcha_response,
        })
      })
    } catch (e) {
      // do nothing, form error handling already there
    } finally {
      recaptchaRef?.current?.reset();
    }
  }

  return (
    <Formik
      initialValues={initialDetailsState}
      onSubmit={submitHandler}
      validateOnBlur
      validationSchema={LogInSchema}
    >
      {({ setFieldValue, isValid }) => (
        <AuthForm title="Welcome Back">
          <Form>
            <FlexColumn gap="25px">
              <FlexColumn gap="15px">
                <Input id="email" name="email" placeholder="Email" />

                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </FlexColumn>

              <FlexRow justify="center">
                <Recaptcha
                  ref={recaptchaRef}
                  name="g_recaptcha_response"
                  verifyCallback={(response) => {
                    if (response)
                      setFieldValue('g_recaptcha_response', response)
                  }}
                />
              </FlexRow>

              <FlexColumn gap="15px">
                <Button type="submit" loading={isLoading} disabled={!isValid}>
                  Sign in
                </Button>

                <FlexColumn gap="6px">
                  <span>
                    Don&apos;t have an account?{' '}
                    <A href={RouteEnum.SIGNUP}>
                      Sign up <HiOutlineArrowNarrowRight />
                    </A>
                  </span>
                  <span>
                    Forgot Password?{' '}
                    <A href={RouteEnum.FORGOT_PASSWORD}>
                      Reset Password
                      <HiOutlineArrowNarrowRight />
                    </A>
                  </span>
                </FlexColumn>
              </FlexColumn>
            </FlexColumn>
          </Form>
        </AuthForm>
      )}
    </Formik>
  )
}

export default LogInForm
